@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components
{
    .text-shadow-dark {
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
}
